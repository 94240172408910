export default {
  isUserLoggedIn (state) {
    if (state.userData && Object.keys(state.userData).length && state.accessToken !== '' && state.accessToken !== 'Bearer null') return true
    else return false
  },
  role (state) {
    if (state.userData) return state.userData.role
    else return undefined
  }
}

// import jwt from '../../http/requests/auth/jwt/index.js'

function loadToken () {
  return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null
}

export default {
  userData: {},
  accessToken: loadToken()
}

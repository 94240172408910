const en = {
  dashboard: 'Dashboard',
  orders: 'Orders',
  order: 'Order',
  bulkOrders: 'Bulk Orders',
  bulkOrder: 'Bulk Order',
  store: 'Store',
  banners: 'Banners',
  banner: 'Banner',
  categories: 'Categories',
  category: 'Category',
  brands: 'Brands',
  brand: 'Brand',
  products: 'Products',
  product: 'Product',
  comboProducts: 'Combo Products',
  comboProduct: 'Combo Product',
  campaigns: 'Campaigns',
  campaign: 'Campaign',
  promoCodes: 'Promo Codes',
  promoCode: 'Promo Code',
  outOfStock: 'Out Of Stock',
  supplierOrders: 'Supplier Orders',
  supplierOrder: 'Supplier Order',
  entertainment: 'Entertainment',
  staff: 'Staff',
  users: 'Users',
  user: 'User',
  operators: 'Operators',
  operator: 'Operator',
  couriers: 'Couriers',
  courier: 'Courier',
  suppliers: 'Suppliers',
  supplier: 'Supplier',
  wholesalers: 'Wholesalers',
  wholesaler: 'Wholesaler',
  settings: 'Settings',
  regions: 'Regions',
  region: 'Region',
  delivery: 'Delivery',
  faqs: 'FAQs',
  contacts: 'Contacts',
  contactUs: 'Contact Us',
  aboutUs: 'About Us',
  termsConditions: 'Terms & Conditions',
  deliveryPolicies: 'Delivery Policies',
  paymentInfo: 'Payment Info',
  orderInfo: 'Order Info',
  smsSenders: 'SMS senders',
  details: 'Details',
  edit: 'Edit',
  delete: 'Delete',
  create: 'Create',
  add_new: 'Add new',
  tableColumns: 'Table columns',
  status: 'Status',
  role: 'Role',
  all: 'All',
  active: 'Active',
  blocked: 'Blocked',
  block: 'Block',
  unblock: 'Unblock',
  confirmBlock: 'Are you sure to block',
  confirmUnblock: 'Are you sure to unblock',
  confirmDelete: 'Are you sure to delete',
  isBlocked: 'Is blocked',
  only_active: 'Only active',
  sale: 'Sale',
  soldOut: 'Sold out',
  passive: 'Passive',
  only_passive: 'Only passive',
  property: 'Property',
  value: 'Value',
  both: 'Both',
  market: 'Market',
  express: 'Express',
  select: 'Select',
  isNotActive: 'is not active',
  popularity: 'Popularity',
  makeOrder: 'Make order',
  ordered: 'Ordered',
  quantity: 'Quantity',
  qt: 'Qt.',
  pcs: 'pcs.',
  city: 'City',
  cities: 'Cities',
  isExpress: 'Is express region?',
  deliveryDuration: 'Delivery time (min.)',
  deliveryTime: 'Delivery time',
  deliveryTypes: 'Delivery types',
  deliveryType: 'Delivery type',
  deliveryTimeRanges: 'Delivery time ranges',
  note: 'Note',
  changePassword: 'Change password',
  password: 'password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  cashWithdraw: 'Cash withdraw',
  confirmCashWithdraw: 'Are you sure to withdraw cash',
  fields: {
    id: 'ID',
    name_tm: 'Name in TM.',
    name_ru: 'Name in RU.',
    name_en: 'Name in EN.',
    code: 'Code',
    shelf_code: 'Shelf code',
    cost: 'Cost',
    name: 'Name',
    username: 'Username',
    email: 'Email',
    phone: 'Phone',
    bar_code: 'Barcode',
    priority: 'Priority',
    title: 'Title',
    description: 'Description',
    attached: 'Attached',
    deadlines: 'Deadlines',
    image: 'Image',
    child: 'Child',
    link: 'Link',
    status: 'Status',
    type: 'Type',
    discount_type: 'Discount type',
    discount_amount: 'Discount amount',
    actions: 'Actions',
    items: 'Items',
    parent: 'Parent',
    isActive: 'Is active?',
    active: 'Active',
    new: 'New',
    isNewProduct: 'Is new product?',
    hit: 'Hit',
    isHitProduct: 'Is hit product?',
    published: 'Published',
    attachTo: 'Attach to',
    state: 'State',
    properties: 'Properties',
    price: 'Price',
    bulkPrice: 'Bulk price',
    minBulkOrder: 'Min. bulk order',
    stock_quantity: 'Stock quantity',
    stock: 'Stock',
    stockQt: 'Stock quantity',
    minStockQt: 'Min. stock quantity',
    maxExpressOrderQt: 'Max. express order qt.',
    weight: 'Weight',
    volume: 'Volume',
    isBulk: 'Is bulk',
    fromTo: 'From - To',
    maxUse: 'Max use counts',
    promoRule: 'Use rule.',
    timeRestricted: 'Time restricted',
    maxUseRestricted: 'User count restricted',
    owner: 'Owner',
    address: 'address',
    total: 'Total',
    subtotal: 'Subtotal',
    isAdd: 'Is addvertisement',
    itemName: 'Item name',
    amount: 'Amount',
    cash: 'Cash',
    tasks: 'Tasks',
    canPublished: 'Pulishable?',
    given_price: 'Given price',
    likes: 'Likes',
    brandId: 'Brand',
    categories: 'Category',
    total_price: 'Price',
    user_name: 'User name',
    user_phone: 'Phone num.',
    delivery_type: 'Delivery type',
    delivery_time: 'Delivery time',
    comment_by_user: 'Comments',
    isSynced: 'Sync.',
    createdAt: 'Created',
    updatedAt: 'Updated'
  },
  and: 'and',
  isLeafCat: 'Is leaf category',
  doesHasParentCat: 'Does has parent category',
  attachTo: 'Attach to',
  type: 'Type',
  state: 'State',
  reason: 'Reason',
  enterReason: 'Enter reason',
  selectOne: 'Select one',
  selectProducts: 'Select products',
  selectCombos: 'Select combo product',
  isActive: 'Is active',
  canBePublished: 'Can be published',
  submit: 'Submit',
  sent: 'Sent',
  cancel: 'Cancel',
  reject: 'Reject',
  upload: 'Upload',
  update: 'Update',
  reorder: 'Reorder',
  change: 'Change',
  remove: 'Remove',
  sales: 'Sales',
  hits: 'Hits',
  sort: 'Sort',
  leafs: 'Leafs',
  only_leaf: 'Only leafs',
  except_leaf: 'Except leafs',
  parents: 'Parents',
  only_parent: 'Only parents',
  image: 'image',
  add_image: 'Add image',
  deleteFile: 'Delete file',
  uploadFile: 'Upload file',
  video: 'video',
  discount: {
    d: 'discount',
    amount: 'Discount amount',
    add: 'Add discount',
    apply: 'Apply discount',
    delete: 'Delete discount',
    type: 'Discount type',
    selectDep: 'Select department',
    selectStartDate: 'Select start date',
    selectEndDate: 'Select end date',
    selectStartTime: 'Select start time',
    selectEndTime: 'Select end date',
    required: 'Required',
    bonus: 'Bonus'
  },
  props: {
    id: 'ID',
    uuid: 'UUID',
    code: 'Code',
    bar_code: 'Barcode',
    shelf_code: 'Shelf code',
    image: 'Image',
    photo: 'Photo',
    name: 'Name',
    username: 'Username',
    email: 'Email',
    phone: 'Phone',
    video: 'Video',
    images: 'Images',
    name_tm: 'name in TM',
    name_ru: 'name in RU',
    name_en: 'name in EN',
    title_tm: 'title in TM',
    title_ru: 'title in RU',
    title_en: 'title in EN',
    description_tm: 'description in TM',
    description_ru: 'description in RU',
    description_en: 'description in EN',
    priority: 'Priority',
    role: 'Role',
    isLeaf: 'Is leaf?',
    parentId: 'Parent ID',
    canPublished: 'Can published?',
    url: 'Url',
    child_type: 'Child type',
    childId: 'Child ID',
    child_categoryIds: 'Child categories',
    child_brandId: 'Child brand ID',
    image_isAdded: 'Image is added?',
    type: 'Type',
    isActive: 'Is active?',
    category_brands: 'Category brands',
    brand_categories: 'Brand categories',
    brandId: 'Brand ID',
    products: 'products',
    collections: 'combo products',
    categoryId: 'category ID',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    brand: 'brand',
    category: 'category',
    categories: 'categories',
    discount: 'Discount',
    price: 'Price',
    price_express: 'Express price',
    given_price: 'Given price',
    isBulk: 'Is bulk',
    isNew: 'Is new',
    isHit: 'Is hit',
    weight: 'Weight',
    volume: 'Volume',
    express_max: 'Max. express order qt.',
    stock_quantity: 'Stock quantity',
    stock_min: 'Stock min. quantity',
    isEnoughInStock: 'Is enough in stock',
    supplierId: 'Supplier ID',
    ownerId: 'Owner ID',
    likes: 'Likes',
    collection_products: 'Combo product items',
    totalPrice: 'Total price',
    payment: 'Payment',
    deliveryType: 'Delivery type',
    deliveryCost: 'Delivery cost',
    deliveryTime: 'Delivery time',
    isSynced: 'Is synced',
    isAdd: 'Is add.',
    preview: 'Preview',
    collection: 'Combo product',
    promos: 'Promo codes',
    orders_delivered: 'Delivered orders',
    orders_rejected: 'Rejected orders',
    isBlocked: 'Is blocked?',
    isVerified: 'Is verified?',
    isSupplier: 'Is supplier?',
    company_name: 'Company name',
    addresses: 'Addresses',
    isExpress: 'Is express',
    cash: 'Cash',
    tasks_all: 'All tasks',
    tasks_today: 'Todays tasks',
    carrier_regions: 'Regions',
    isCredited: 'Is creditable',
    price_bulk: 'Bulk price',
    bulk_min: 'Bulk order min. qt',
    from: 'From',
    to: 'To',
    ordered: 'Ordered',
    unordered: 'Left',
    password: 'password',
    in_carrier_stock: 'Carriers have',
    supplier_products: 'Supplier products'
  },
  notify: {
    success: 'Success',
    sCreated: 'successfully created!',
    sAdded: 'successfully added!',
    sUploaded: 'successfully uploaded!',
    sUpdated: 'successfully updated!',
    sAccepted: 'successfully accepted!',
    sSent: 'successfully sent!',
    sDelivered: 'successfully delivered!',
    sRejected: 'successfully rejected!',
    sNotDelivered: 'successfully not delivered!',
    sDeleted: 'successfully deleted!',
    sRemoved: 'successfully removed!',
    error: 'Error',
    errOccured: 'Something went wrong.',
    formInvalid: 'Form is invalid. Check one more time.'
  },
  search: 'search',
  questionTurkmen: 'Question in TM',
  questionEnglish: 'Question in EN',
  questionRussian: 'Question in RU',
  answerTurkmen: 'Answer in TM',
  answerEnglish: 'Answer in EN',
  answerRussian: 'Answer in RU',
  tm: 'Turkmen',
  en: 'English',
  ru: 'Russian',
  fillAllFields: 'Please, fill all required fields!',
  addresses: 'Addresses',
  phoneNumbers: 'Phone numbers',
  emailAddresses: 'Email addresses',
  socialAccounts: 'Social networks',
  workTime: 'Working hours',
  orderDetails: 'Order details',
  orderItems: 'Order products',
  userDetails: 'User details',
  courierDetails: 'Courier details',
  print: 'Print',
  invoice: 'Invoice',
  date: 'Date',
  recipient: 'Recipient',
  deliveries: 'Deliveries',
  localStock: 'Local stock',
  orderPage: {
    all: 'All',
    pending: 'Pending',
    accepted: 'Accepted',
    accept: 'Accept',
    rejected: 'Rejected',
    reject: 'Reject',
    delivered: 'Delivered',
    sendTo: 'Send',
    send: 'Send',
    sent: 'Sent',
    deliver: 'Deliver',
    notDelivered: 'Not delivered',
    not_delivered: 'Not delivered',
    notDeliver: 'Not deliver',
    filter: 'Filter',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    selectDate: 'Select date',
    searchBy: 'Search by',
    rejectiongOrder: 'Rejecting order',
    confirmAccept: 'Confirm accept',
    acceptingOrder: 'Accepting order',
    sendingOrder: 'Sending order',
    confirmDeliver: 'Confir deliver',
    deliveringOrder: 'Delivering order',
    notDeliveringOrder: 'Not delivering order',
    rating: 'Rating',
    userComment: 'User comments',
    undefined: 'Undefined'
  },
  profile: 'Profile',
  logOut: 'Logout',
  new: 'New',
  notifications: 'Notifications',
  file_size_max: 'File size is bigger than allowed!',
  imageNotAdded: 'Image isn\'t added, please add image before!!'
}
export default en

/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {title: 'dashboard', url: '/', icon: 'HomeIcon', is_bookmarked: true},
      {title: 'orders', url: '/orders', icon: 'ShoppingCartIcon', is_bookmarked: true},
      {title: 'products', url: '/products', icon: 'BoxIcon', is_bookmarked: true},
      {title: 'comboProducts', url: '/combo-products', icon: 'ArchiveIcon', is_bookmarked: true},
      {title: 'campaigns', url: '/campaigns', icon: 'TagIcon', is_bookmarked: true},
      {title: 'users', url: '/users', icon: 'UsersIcon', is_bookmarked: true},
      {title: 'couriers', url: '/couriers', icon: 'TruckIcon', is_bookmarked: true},
      {title: 'supplierOrders', url: '/supplier-orders', icon: 'TerminalIcon', is_bookmarked: true},
      {title: 'entertainment', url: '/video-banners', icon: 'YoutubeIcon', is_bookmarked: true},
      {title: 'regions', url: '/regions', icon: 'MapIcon', is_bookmarked: true},
      {title: 'banners', url: '/banners', icon: 'ImageIcon', is_bookmarked: true},
      {title: 'categories', url: '/categories', icon: 'GridIcon', is_bookmarked: true},
      {title: 'brands', url: '/brands', icon: 'AwardIcon', is_bookmarked: true}
    ]
  }
}
/* eslint-enable */

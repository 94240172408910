import axios from 'axios'
// const baseURL = 'http://192.168.0.121:5115/api/v1/admin'

//Localhost
// const baseURL = 'http://192.168.0.145:5125/api/v1/admin'
const baseURL = 'https://api.magnit.com.tm/api/v1/admin'

//Server
// const baseURL = 'https://setapi.pikir.biz/api/v1/admin'

export default axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

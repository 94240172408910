import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import axios from './axios.js'

import './filters/filters.js'
import '../themeConfig.js'
import './globalComponents.js'

//ToDo: Uncomment on production
// import firebaseMessaging from './firebase'

import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css'
import './assets/scss/main.scss'
import '@/assets/css/main.css'

import router from './router'
import store from './store/store'
import i18n from './i18n/i18n'
import acl from './acl/acl'

import { VueHammer } from 'vue2-hammer'
import VeeValidate from 'vee-validate'

//LOCALHOST
Vue.prototype.$baseUrl = 'https://api.magnit.com.tm'
// Vue.prototype.$baseUrl = 'http://192.168.0.145:5125'

//ToDo: Uncomment on production
// Vue.prototype.$messaging = firebaseMessaging
Vue.prototype.$http = axios

axios.interceptors.response.use(function (response) {
  return Promise.resolve(response)
}, function (error) {
  const { response: { status } } = error

  if (!error.response) {
    return Promise.reject(error)
  }
  if (status === 403) {
    router.push('/not-authorized')
  }
  if (status === 401) {
    // console.log('Unauthorized user!')
  }
  return Promise.reject(error)
})

Vue.use(Vuesax)
Vue.use(VueHammer)
Vue.use(VeeValidate)

import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
require('./assets/css/iconfont.css')
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')

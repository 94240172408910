import axios from '@/axios.js'

export default {
  login (username, pwd) {
    return axios.post('/login', {
      username,
      password: pwd
    })
  },
  me (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    return axios.get('/get-me')
  }
}

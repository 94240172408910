import axios from '@/axios.js'

export default {
  SET_BEARER (state, accessToken) {
    state.accessToken = `Bearer ${accessToken}`
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }, 
  LOGIN_USER (state, payload) {
    state.userData = payload
    localStorage.setItem('userRole', payload.role)
  },
  LOGOUT_USER (state) {
    state.userData = null
    state.accessToken = null
    localStorage.removeItem('userRole')
    localStorage.removeItem('accessToken')
    axios.defaults.headers.common['Authorization'] = null
  }
}

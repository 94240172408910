const ru = {
  dashboard: 'Панель',
  orders: 'Заказы',
  order: 'Заказ',
  bulkOrders: 'Оптовые Заказы',
  bulkOrder: 'Оптовый Заказ',
  store: 'Магазин',
  banners: 'Баннеры',
  banner: 'Баннер',
  categories: 'Категории',
  category: 'Категория',
  brands: 'Бренды',
  brand: 'Бренд',
  products: 'Товары',
  product: 'Товар',
  comboProducts: 'Комбо Товары',
  comboProduct: 'Комбо Товар',
  campaigns: 'Акции',
  campaign: 'Акция',
  promoCodes: 'Промо коды',
  promoCode: 'Промо код',
  outOfStock: 'Распродано',
  supplierOrders: 'Заказы Поставщикам',
  supplierOrder: 'Заказ Поставщику',
  entertainment: 'Развлечение',
  staff: 'Штат',
  users: 'Пользователи',
  user: 'Пользователь',
  operators: 'Операторы',
  operator: 'Оператор',
  couriers: 'Курьеры',
  courier: 'Курьер',
  suppliers: 'Поставщики',
  supplier: 'Поставщик',
  wholesalers: 'Оптовики',
  wholesaler: 'Оптовик',
  settings: 'Настройки',
  regions: 'Регионы',
  region: 'Регион',
  delivery: 'Доставка',
  faqs: 'Вопросы-Ответы',
  contacts: 'Контакты',
  contactUs: 'Связаться с нами',
  aboutUs: 'О нас',
  termsConditions: 'Условия',
  deliveryPolicies: 'Политика доставки',
  paymentInfo: 'Политика оплаты',
  orderInfo: 'О заказе',
  smsSenders: 'Отправители СМС',
  details: 'Детально',
  edit: 'Редактировать',
  delete: 'Удалить',
  create: 'Создать',
  add_new: 'Добавить',
  tableColumns: 'Столбцы таблицы',
  status: 'Статус',
  role: 'Роль',
  all: 'Все',
  active: 'Активные',
  blocked: 'Блокированные',
  block: 'Заблокировать',
  unblock: 'Разблокировать',
  confirmBlock: 'Вы действительно хотите заблокировать',
  confirmUnblock: 'Вы действительно хотите разблокировать',
  confirmDelete: 'Вы действительно хотите удалить',
  isBlocked: 'Заблокирован',
  only_active: 'Толко актив',
  sale: 'Скидочные',
  soldOut: 'Распроданные',
  passive: 'Пассивные',
  only_passive: 'Только пассив',
  property: 'Свойства',
  value: 'Значение',
  both: 'Оба',
  market: 'Маркет',
  express: 'Экспресс',
  select: 'Выберите',
  isNotActive: 'не активна',
  popularity: 'Популярность',
  makeOrder: 'Отправить заказ',
  ordered: 'Заказан',
  quantity: 'Количество',
  qt: 'Кол.',
  pcs: 'шт.',
  city: 'Город',
  cities: 'Города',
  isExpress: 'Экспресс регион?',
  deliveryDuration: 'Время доставки(мин.)',
  deliveryTime: 'Время доставки',
  deliveryTypes: 'Виды доставки',
  deliveryType: 'Вид доставки',
  deliveryTimeRanges: 'Временные диапазоны доставки',
  note: 'Заметка',
  changePassword: 'Поменять пароль',
  password: 'пароль',
  newPassword: 'Новый пароль',
  confirmPassword: 'Подтвердите пароль',
  cashWithdraw: 'Обналичить',
  confirmCashWithdraw: 'Вы действительно хотите обналичить',
  fields: {
    id: 'ID',
    name_tm: 'Название на ТКМ.',
    name_ru: 'Название на РУС.',
    name_en: 'Название на АНГ.',
    code: 'Код',
    shelf_code: 'Код полки',
    cost: 'Стоимость',
    name: 'Имя',
    username: 'Имя пользователя',
    email: 'Эл. почта',
    phone: 'Тел.',
    bar_code: 'Баркод',
    priority: 'Приоритет',
    title: 'Название',
    description: 'Описание',
    attached: 'Привязан',
    deadlines: 'Сроки',
    image: 'фото',
    child: 'Дочерняя',
    link: 'Ссылка',
    status: 'Статус',
    type: 'Тип',
    discount_type: 'Тип скидки',
    discount_amount: 'Объем скидки',
    actions: 'Действия',
    items: 'Товары',
    parent: 'Родитель',
    isActive: 'Активен ли?',
    active: 'Активен',
    new: 'Новинка',
    isNewProduct: 'Это новый товар?',
    hit: 'Хит товар',
    isHitProduct: 'Это хит товар?',
    published: 'Публикован',
    attachTo: 'Привязать',
    state: 'Состояние',
    properties: 'Свойства',
    price: 'Цена',
    bulkPrice: 'Оптовая цена',
    minBulkOrder: 'Мин кол. опт. заказа',
    stock_quantity: 'Количество в складе',
    stock: 'Сток',
    stockQt: 'Количество в складе',
    minStockQt: 'Мин. кол. в складе',
    maxExpressOrderQt: 'Макс. кол. экс. заказа',
    weight: 'Вес',
    volume: 'Габариты',
    isBulk: 'Оптовый товар',
    fromTo: 'Начало - Конец',
    maxUse: 'Кол. использования',
    promoRule: 'Правила исп.',
    timeRestricted: 'Ограничение по времени',
    maxUseRestricted: 'Ограничение кол. исп.',
    owner: 'Хозяин',
    address: 'Адрес',
    total: 'Сумма',
    subtotal: 'Подытог',
    isAdd: 'Реклама',
    itemName: 'Название товара',
    amount: 'Количество',
    cash: 'Наличка',
    tasks: 'Доставки',
    canPublished: 'Публикован?',
    given_price: 'Пред. цена',
    likes: 'Лайки',
    brandId: 'Бренд',
    categories: 'Категория',
    total_price: 'Цена',
    user_name: 'Имя поль.',
    user_phone: 'Тел. номер',
    delivery_type: 'Тип доставки',
    delivery_time: 'Время доставки',
    comment_by_user: 'Комментарии',
    isSynced: 'Синхронизован',
    createdAt: 'Создан',
    updatedAt: 'Редактирован'
  },
  and: 'и',
  isLeafCat: 'Листовая ли категория',
  doesHasParentCat: 'Имеется ли родительская категория',
  attachTo: 'Привязать',
  type: 'Тип',
  state: 'Состояние',
  reason: 'Причина',
  enterReason: 'Введите причину',
  selectOne: 'Выберите один',
  selectProducts: 'Выберите товары',
  selectCombos: 'Выберите комбо товары',
  isActive: 'Активен ли',
  canBePublished: 'Готов ли к публикации',
  submit: 'Подтвердить',
  sent: 'Отправить',
  cancel: 'Отменить',
  reject: 'Отклонить',
  upload: 'Загрузить',
  update: 'Обновить',
  reorder: 'Переместить',
  change: 'Поменять',
  remove: 'Удалить',
  sales: 'Скидки',
  hits: 'Хиты',
  sort: 'Сортировать',
  leafs: 'Листовые',
  only_leaf: 'Толко листовые',
  except_leaf: 'Кроме листовых',
  parents: 'Родители',
  only_parent: 'Толко родители',
  image: 'рисунок',
  add_image: 'Добавить фото',
  deleteFile: 'Удалить файл',
  uploadFile: 'Загрузить файл',
  video: 'видео',
  discount: {
    d: 'cкидка',
    amount: 'Объем скидки',
    add: 'Добавить скидку',
    apply: 'Применить скидку',
    delete: 'Удалить скидку',
    type: 'Вариант скидки',
    selectDep: 'Выберите отдел',
    selectStartDate: 'Выберите начальное число',
    selectEndDate: 'Выберите конечное число',
    selectStartTime: 'Выберите начальное время',
    selectEndTime: 'Выберите конечное время',
    required: 'Необходимое кол.',
    bonus: 'Бонусное кол.'
  },
  props: {
    id: 'ID',
    uuid: 'UUID',
    code: 'Код',
    bar_code: 'Баркод',
    shelf_code: 'Код полки',
    image: 'Фото',
    photo: 'Фото',
    name: 'Имя',
    username: 'Имя пользователя',
    email: 'Эл. почта',
    phone: 'Телефон',
    video: 'Видео',
    images: 'Фотографии',
    name_tm: 'имя на ТКМ.',
    name_ru: 'имя на РУС.',
    name_en: 'имя на АНГ.',
    title_tm: 'название на ТКМ.',
    title_ru: 'название на РУС.',
    title_en: 'название на АНГ.',
    description_tm: 'описание на ТКМ.',
    description_ru: 'описание на РУС.',
    description_en: 'описание на АНГ.',
    priority: 'Приоритет',
    role: 'Роль',
    isLeaf: 'Листовая?',
    parentId: 'ID Родителя',
    canPublished: 'Для публикации готов?',
    url: 'Ссылка',
    child_type: 'Тип дочернего эл.',
    childId: 'ID дочернего эл.',
    child_categoryIds: 'Категории дочернего эл.',
    child_brandId: 'Бренд дочернего эл.',
    image_isAdded: 'Рисунок добавлен?',
    type: 'Тип',
    isActive: 'Активен ли?',
    category_brands: 'Бренды категории',
    brand_categories: 'Категории бренда',
    brandId: 'ID бренда',
    products: 'товары',
    collections: 'комбо товары',
    categoryId: 'ID категории',
    createdAt: 'Создан',
    updatedAt: 'Редактирован',
    brand: 'бренд',
    category: 'акция категории',
    categories: 'категории',
    discount: 'Скидка',
    price: 'Цена',
    price_express: 'Экспресс цена',
    given_price: 'Изначальная цена',
    isBulk: 'Оптовый товар',
    isNew: 'Новинка',
    isHit: 'Хит товар',
    weight: 'Вес',
    volume: 'Габариты',
    express_max: 'Макс. кол. экс. заказа',
    stock_quantity: 'Количество в складе',
    stock_min: 'Мин. кол. в складе',
    isEnoughInStock: 'Достаточна ли в складе',
    supplierId: 'ID поставщика',
    ownerId: 'ID хозяина',
    likes: 'Нравится',
    collection_products: 'Товары коллекции',
    totalPrice: 'Общая стоимость',
    payment: 'Оплата',
    deliveryType: 'Вид доставки',
    deliveryCost: 'Стоимость доставки',
    deliveryTime: 'Время доставки',
    isSynced: 'Синхронизован',
    isAdd: 'Реклама',
    preview: 'Превью',
    collection: 'Комбо товар',
    promos: 'Промо коды',
    orders_delivered: 'Доставленные заказы',
    orders_rejected: 'Отмененные заказы',
    isBlocked: 'Заблокирован?',
    isVerified: 'Подтвержденный?',
    isSupplier: 'Поставщик?',
    company_name: 'Название компании',
    addresses: 'Адреса',
    isExpress: 'Экспресс ли',
    cash: 'Наличка',
    tasks_all: 'Все доставки',
    tasks_today: 'Сегодняшние доставки',
    carrier_regions: 'Регионы',
    isCredited: 'Кредитоспособный',
    price_bulk: 'Оптовая цена',
    bulk_min: 'Мин. кол. оптовом заказе',
    from: 'Начало',
    to: 'Конец',
    ordered: 'Заказано',
    unordered: 'Остаток',
    password: 'пароль',
    in_carrier_stock: 'У курьерах',
    supplier_products: 'Товары'
  },
  notify: {
    success: 'Успешно',
    sCreated: 'успешно создан!',
    sAdded: 'успешно добавлен!',
    sUploaded: 'успешно загружен!',
    sUpdated: 'успешно обнавлен!',
    sAccepted: 'успешно принят!',
    sSent: 'успешно отправлен!',
    sDelivered: 'успешно доставлен!',
    sRejected: 'успешно отказан!',
    sNotDelivered: 'не доставлен!',
    sDeleted: 'успешно удален!',
    sRemoved: 'успешно убран!',
    error: 'Ошибка',
    errOccured: 'Возникла ошибка',
    formInvalid: 'Форма не корректна.'
  },
  search: 'искать',
  questionTurkmen: 'Вопрос на ТКМ',
  questionEnglish: 'Вопрос на АНГ',
  questionRussian: 'Вопрос на РУС',
  answerTurkmen: 'Ответ на ТКМ',
  answerEnglish: 'Ответ на АНГ',
  answerRussian: 'Ответ на РУС',
  tm: 'Туркменский',
  en: 'Английский',
  ru: 'Русский',
  fillAllFields: 'Пожалуйста, заполните все поля формы!',
  addresses: 'Адреса',
  phoneNumbers: 'Телефонные номера',
  emailAddresses: 'Эл. почтовые адреса',
  socialAccounts: 'Социальные сети',
  workTime: 'Рабочее время',
  orderDetails: 'Подробности заказа',
  orderItems: 'Продукты под заказ',
  userDetails: 'Сведения о пользователе',
  courierDetails: 'Сведения о курьере',
  print: 'Печать',
  invoice: 'Счет-фактура',
  date: 'Дата',
  recipient: 'Получатель',
  deliveries: 'Доставки',
  localStock: 'Локальный запас',
  orderPage: {
    all: 'Все',
    pending: 'В ожидании',
    accepted: 'Принят',
    accept: 'Принять',
    rejected: 'Отклонен',
    reject: 'Отклонить',
    delivered: 'Доставлен',
    sendTo: 'Отправить',
    send: 'Отправлен',
    sent: 'Отправлен',
    deliver: 'Доставить',
    notDelivered: 'Не доставлен',
    not_delivered: 'Не доставлен',
    notDeliver: 'Не доставить',
    filter: 'Фильтр',
    dateFrom: 'Дата, с',
    dateTo: 'Дата, до',
    selectDate: 'Выберите дату',
    searchBy: 'Искать по',
    rejectiongOrder: 'Отклоняете заказ',
    confirmAccept: 'Подтвердите прием',
    acceptingOrder: 'Принимаете заказ',
    sendingOrder: 'Отправляете заказ',
    confirmDeliver: 'Подтвердите доставку',
    deliveringOrder: 'Доставляете заказ',
    notDeliveringOrder: 'Не доставляете заказ',
    rating: 'Рейтинг',
    userComment: 'Комментарии поль.',
    undefined: 'Не явно'
  },
  profile: 'Профиль',
  logOut: 'Выйти',
  new: 'Новых',
  notifications: 'Уведомления',
  file_size_max: 'Размер файла больше допустимого!',
  imageNotAdded: 'Рисунок не добавлен, сперва добавте рисунок!!'
}

export default ru
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home'),
          meta: {
            rule: 'stock_operator'
          }
        },
        // Order Routes////////////////////////////////////////////////////////////////
        {
          path: '/orders',
          name: 'orders',
          component: () => import('./views/pages/orders/index'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'orders', title: 'Orders', active: true }
            ],
            pageTitle: 'orders',
            rule: 'stock_operator'
          }
        },
        {
          path: '/orders/:id',
          name: 'orderShow',
          component: () => import('./views/pages/orders/show'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'orders', title: 'Orders', url: '/orders'},
              { i18n: 'invoice', title: 'Invoice', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/orders/:id/invoice',
          name: 'orderInvoice',
          component: () => import('./views/pages/orders/invoice'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'orders', title: 'Orders', url: '/orders'},
              { i18n: 'invoice', title: 'Invoice', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        // {
        //   path: '/orders/edit',
        //   name: 'orders-edit',
        //   component: () => import('./views/pages/orders/edit'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { i18n: 'orders', title: 'Orders', url: '/orders'},
        //       { i18n: 'edit', title: 'Edit', active: true }
        //     ],
        //     pageTitle: 'edit',
        //     rule: 'operator'
        //   }
        // },
        // Bulk order Routes////////////////////////////////////////////////////////////////
        {
          path: '/bulk-orders',
          name: 'blukOrders',
          component: () => import('./views/pages/wholesaler-orders/index'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'bulkOrders', title: 'Bulk orders', active: true }
            ],
            pageTitle: 'bulkOrders',
            rule: 'stock_operator'
          }
        },
        {
          path: '/bulk-orders/:id',
          name: 'bulkOrderShow',
          component: () => import('./views/pages/wholesaler-orders/show'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'bulkOrders', title: 'Bulk orders', url: '/bulk-orders'},
              { i18n: 'invoice', title: 'Invoice', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/bulk-orders/edit',
          name: 'bulkOrderEdit',
          component: () => import('./views/pages/wholesaler-orders/edit'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'bulkOrders', title: 'Bulk orders', url: '/bulk-orders'},
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'operator'
          }
        },
        // Banner Routes////////////////////////////////////////////////////////////////
        {
          path: '/banners',
          name: 'banners',
          component: () => import('./views/pages/banners/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'banners', title: 'Banners', active: true }
            ],
            pageTitle: 'banners',
            rule: 'stock_operator'
          }
        },
        {
          path: '/banners/create',
          name: 'bannerCreate',
          component: () => import('./views/pages/banners/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'banners', title: 'Banners', url: '/banners' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/banners/:id',
          name: 'bannerShow',
          component: () => import('./views/pages/banners/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'banners', title: 'Banners', url: '/banners' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/banners/:id/edit',
          name: 'bannerEdit',
          component: () => import('./views/pages/banners/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'banners', title: 'Banners', url: '/banners' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Category Routes////////////////////////////////////////////////////////////////
        {
          path: '/categories',
          name: 'categories',
          component: () => import('./views/pages/categories/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'categories', title: 'Categories', active: true }
            ],
            pageTitle: 'categories',
            rule: 'stock_operator'
          }
        },
        {
          path: '/categories/create',
          name: 'categoryCreate',
          component: () => import('./views/pages/categories/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'categories', title: 'Categories', url: '/categories' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/categories/:id',
          name: 'categoryShow',
          component: () => import('./views/pages/categories/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'categories', title: 'Categories', url: '/categories' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/categories/:id/edit',
          name: 'categoryEdit',
          component: () => import('./views/pages/categories/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'categories', title: 'Categories', url: '/categories' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Brands Routes////////////////////////////////////////////////////////////////
        {
          path: '/brands',
          name: 'brands',
          component: () => import('./views/pages/brands/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'brands', title: 'Brands', active: true }
            ],
            pageTitle: 'brands',
            rule: 'stock_operator'
          }
        },
        {
          path: '/brands/create',
          name: 'brandCreate',
          component: () => import('./views/pages/brands/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'brands', title: 'Brands', url: '/brands' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/brands/:id',
          name: 'brandShow',
          component: () => import('./views/pages/brands/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'brands', title: 'Brands', url: '/brands' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/brands/:id/edit',
          name: 'brandEdit',
          component: () => import('./views/pages/brands/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'brands', title: 'Brands', url: '/brands' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Products Routes////////////////////////////////////////////////////////////////
        {
          path: '/products',
          name: 'products',
          component: () => import('./views/pages/products/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'products', title: 'Products', active: true }
            ],
            pageTitle: 'products',
            rule: 'stock_operator'
          }
        },
        {
          path: '/products/create',
          name: 'productCreate',
          component: () => import('./views/pages/products/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'products', title: 'Products', url: '/products' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/products/:id',
          name: 'productShow',
          component: () => import('./views/pages/products/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'products', title: 'Products', url: '/products' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/products/:id/edit',
          name: 'productEdit',
          component: () => import('./views/pages/products/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'products', title: 'Products', url: '/products' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Combo Products Routes////////////////////////////////////////////////////////////////
        {
          path: '/combo-products',
          name: 'combos',
          component: () => import('./views/pages/combos/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'comboProducts', title: 'Combo Products', active: true }
            ],
            pageTitle: 'comboProducts',
            rule: 'stock_operator'
          }
        },
        {
          path: '/combo-products/create',
          name: 'comboCreate',
          component: () => import('./views/pages/combos/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'comboProducts', title: 'Combo Products', url: '/combo-products' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/combo-products/:id',
          name: 'comboShow',
          component: () => import('./views/pages/combos/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'comboProducts', title: 'Combo Products', url: '/combo-products' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/combo-products/:id/edit',
          name: 'comboEdit',
          component: () => import('./views/pages/combos/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'comboProducts', title: 'Combo Products', url: '/combo-products' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Campaigns Routes////////////////////////////////////////////////////////////////
        {
          path: '/campaigns',
          name: 'campaigns',
          component: () => import('./views/pages/campaigns/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'campaigns', title: 'Campaigns', active: true }
            ],
            pageTitle: 'campaigns',
            rule: 'stock_operator'
          }
        },
        {
          path: '/campaigns/create',
          name: 'campaignCreate',
          component: () => import('./views/pages/campaigns/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'campaigns', title: 'Campaigns', url: '/campaigns' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/campaigns/:id',
          name: 'campaignShow',
          component: () => import('./views/pages/campaigns/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'campaigns', title: 'Campaigns', url: '/campaigns' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/campaigns/:id/edit',
          name: 'campaignEdit',
          component: () => import('./views/pages/campaigns/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'campaigns', title: 'Campaigns', url: '/campaigns' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Promocode Routes////////////////////////////////////////////////////////////////
        {
          path: '/promos',
          name: 'promos',
          component: () => import('./views/pages/promos/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'promoCodes', title: 'Promocodes', active: true }
            ],
            pageTitle: 'promoCodes',
            rule: 'operator'
          }
        },
        {
          path: '/promos/create',
          name: 'promoCreate',
          component: () => import('./views/pages/promos/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'promoCodes', title: 'Promocodes', url: '/promos' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        // Out of stock Routes////////////////////////////////////////////////////////////////
        {
          path: '/soldout',
          name: 'soldout',
          component: () => import('./views/pages/soldout/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'outOfStock', title: 'Out of stock', active: true }
            ],
            pageTitle: 'outOfStock',
            rule: 'stock_operator'
          }
        },
        // Supplier order Routes////////////////////////////////////////////////////////////////
        {
          path: '/supplier-orders',
          name: 'supplierOrders',
          component: () => import('./views/pages/supplierOrders/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'supplierOrders', title: 'Supplier orders', active: true }
            ],
            pageTitle: 'supplierOrders',
            rule: 'stock_operator'
          }
        },
        {
          path: '/supplier-orders/:id',
          name: 'supplierOrderShow',
          component: () => import('./views/pages/supplierOrders/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'supplierOrders', title: 'Supplier orders', url: '/supplier-orders' },
              { i18n: 'details', title: 'Order details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/supplier-orders/:id/edit',
          name: 'supplierOrderEdit',
          component: () => import('./views/pages/supplierOrders/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'supplierOrders', title: 'Supplier orders', url: '/supplier-orders' },
              { i18n: 'edit', title: 'Order edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'stock_operator'
          }
        },
        // Video Routes////////////////////////////////////////////////////////////////
        {
          path: '/video-banners',
          name: 'vBanners',
          component: () => import('./views/pages/videos/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'entertainment', title: 'Video Clips', active: true }
            ],
            pageTitle: 'entertainment',
            rule: 'stock_operator'
          }
        },
        {
          path: '/video-banners/create',
          name: 'vBannerCreate',
          component: () => import('./views/pages/videos/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'entertainment', title: 'Video Clips', url: '/video-banners' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'stock_operator'
          }
        },
        {
          path: '/video-banners/:id',
          name: 'vBannerShow',
          component: () => import('./views/pages/videos/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'entertainment', title: 'Video Clips', url: '/video-banners' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/video-banners/:id/edit',
          name: 'vBannerEdit',
          component: () => import('./views/pages/videos/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'entertainment', title: 'Video Clips', url: '/video-banners' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'stock_operator'
          }
        },
        // Users Routes////////////////////////////////////////////////////////////////
        {
          path: '/users',
          name: 'users',
          component: () => import('./views/pages/users/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'users', title: 'Users', active: true }
            ],
            pageTitle: 'users',
            rule: 'stock_operator'
          }
        },
        {
          path: '/users/:id',
          name: 'userShow',
          component: () => import('./views/pages/users/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'users', title: 'Users', url: '/users' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/users/:id/edit',
          name: 'userEdit',
          component: () => import('./views/pages/users/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'users', title: 'Users', url: '/users' },
              { i18n: 'edit', title: 'Edit user', active: true }
            ],
            pageTitle: 'edit',
            rule: 'operator'
          }
        },
        // Operators Routes////////////////////////////////////////////////////////////////
        {
          path: '/operators',
          name: 'operators',
          component: () => import('./views/pages/operators/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'operators', title: 'Operators', active: true }
            ],
            pageTitle: 'operators',
            rule: 'superadmin'
          }
        },
        {
          path: '/operators/create',
          name: 'operatorCreate',
          component: () => import('./views/pages/operators/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'operators', title: 'Operators', url: '/operators' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'superadmin'
          }
        },
        {
          path: '/operators/:id',
          name: 'operatorShow',
          component: () => import('./views/pages/operators/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'operators', title: 'Operators', url: '/operators' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'admin'
          }
        },
        {
          path: '/operators/:id/edit',
          name: 'operatorEdit',
          component: () => import('./views/pages/operators/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'operators', title: 'Operators', url: '/operators' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'superadmin'
          }
        },
        // Couriers Routes////////////////////////////////////////////////////////////////
        {
          path: '/couriers',
          name: 'couriers',
          component: () => import('./views/pages/couriers/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'couriers', title: 'Couriers', active: true }
            ],
            pageTitle: 'couriers',
            rule: 'stock_operator'
          }
        },
        {
          path: '/couriers/create',
          name: 'courierCreate',
          component: () => import('./views/pages/couriers/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'couriers', title: 'Couriers', url: '/couriers' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'superadmin'
          }
        },
        {
          path: '/couriers/:id',
          name: 'courierShow',
          component: () => import('./views/pages/couriers/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'couriers', title: 'Couriers', url: '/couriers' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/couriers/:id/edit',
          name: 'courierEdit',
          component: () => import('./views/pages/couriers/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'couriers', title: 'Couriers', url: '/couriers' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'superadmin'
          }
        },
        // Suppliers Routes////////////////////////////////////////////////////////////////
        {
          path: '/suppliers',
          name: 'suppliers',
          component: () => import('./views/pages/suppliers/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'suppliers', title: 'Suppliers', active: true }
            ],
            pageTitle: 'suppliers',
            rule: 'stock_operator'
          }
        },
        {
          path: '/suppliers/create',
          name: 'supplierCreate',
          component: () => import('./views/pages/suppliers/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'suppliers', title: 'Suppliers', url: '/suppliers' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'superadmin'
          }
        },
        {
          path: '/suppliers/:id',
          name: 'supplierShow',
          component: () => import('./views/pages/suppliers/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'suppliers', title: 'Suppliers', url: '/suppliers' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/suppliers/:id/edit',
          name: 'supplierEdit',
          component: () => import('./views/pages/suppliers/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'suppliers', title: 'Suppliers', url: '/suppliers' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'superadmin'
          }
        },
        // Suppliers Routes////////////////////////////////////////////////////////////////
        {
          path: '/wholesalers',
          name: 'wholesalers',
          component: () => import('./views/pages/wholesalers/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'wholesalers', title: 'Wholesalers', active: true }
            ],
            pageTitle: 'wholesalers',
            rule: 'stock_operator'
          }
        },
        {
          path: '/wholesalers/create',
          name: 'wholesalerCreate',
          component: () => import('./views/pages/wholesalers/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'wholesalers', title: 'Wholesalers', url: '/wholesalers' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'superadmin'
          }
        },
        {
          path: '/wholesalers/:id',
          name: 'wholesalerShow',
          component: () => import('./views/pages/wholesalers/show.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'wholesalers', title: 'Wholesalers', url: '/wholesalers' },
              { i18n: 'details', title: 'Details', active: true }
            ],
            pageTitle: 'details',
            rule: 'stock_operator'
          }
        },
        {
          path: '/wholesalers/:id/edit',
          name: 'wholesalerEdit',
          component: () => import('./views/pages/wholesalers/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'wholesalers', title: 'Wholesalers', url: '/wholesalers' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'superadmin'
          }
        },
        // Region Routes////////////////////////////////////////////////////////////////
        {
          path: '/regions',
          name: 'regions',
          component: () => import('./views/pages/regions/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'regions', title: 'Regions', active: true }
            ],
            pageTitle: 'regions',
            rule: 'stock_operator'
          }
        },
        {
          path: '/regions/create',
          name: 'regionCreate',
          component: () => import('./views/pages/regions/create.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'regions', title: 'Regions', url: '/regions' },
              { i18n: 'create', title: 'Create', active: true }
            ],
            pageTitle: 'create',
            rule: 'admin'
          }
        },
        {
          path: '/regions/:type/:id/edit',
          name: 'regionEdit',
          component: () => import('./views/pages/regions/edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'regions', title: 'Regions', url: '/regions' },
              { i18n: 'edit', title: 'Edit', active: true }
            ],
            pageTitle: 'edit',
            rule: 'admin'
          }
        },
        // Delivery information /////////////////////////////////////////////////////////////
        {
          path: '/deliveries',
          name: 'deliveries',
          component: () => import('./views/pages/deliveries/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'delivery', title: 'Deliveries', active: true }
            ],
            pageTitle: 'delivery',
            rule: 'stock_operator'
          }
        },
        // FAQs /////////////////////////////////////////////////////////////
        {
          path: '/faq',
          name: 'faq',
          component: () => import('./views/pages/faq/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'faqs', title: 'FAQ\'s', active: true }
            ],
            pageTitle: 'faqs',
            rule: 'operator'
          }
        },
        // SMS senders /////////////////////////////////////////////////////////////
        {
          path: '/sms-senders',
          name: 'smsSenders',
          component: () => import('./views/pages/sms-senders/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'smsSenders', title: 'SMS Senders', active: true }
            ],
            pageTitle: 'smsSenders',
            rule: 'admin'
          }
        },
        // Text informations /////////////////////////////////////////////////////////////
        {
          path: '/contact',
          name: 'contact',
          component: () => import('./views/pages/texts/contact.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'contactUs', title: 'Contact us', active: true }
            ],
            pageTitle: 'contactUs',
            rule: 'admin'
          }
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('./views/pages/texts/about.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'aboutUs', title: 'About us', active: true }
            ],
            pageTitle: 'aboutUs',
            rule: 'admin'
          }
        },
        {
          path: '/terms',
          name: 'terms',
          component: () => import('./views/pages/texts/terms.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'termsConditions', title: 'Terms & conditions', active: true }
            ],
            pageTitle: 'termsConditions',
            rule: 'admin'
          }
        },
        {
          path: '/delivery-info',
          name: 'delivery-info',
          component: () => import('./views/pages/texts/delivery.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'deliveryPolicies', title: 'Delivery rules', active: true }
            ],
            pageTitle: 'deliveryPolicies',
            rule: 'admin'
          }
        },
        {
          path: '/payment-info',
          name: 'payment-info',
          component: () => import('./views/pages/texts/payment.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'paymentInfo', title: 'Payment rules', active: true }
            ],
            pageTitle: 'paymentInfo',
            rule: 'admin'
          }
        },
        {
          path: '/order-info',
          name: 'order-info',
          component: () => import('./views/pages/texts/order.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { i18n: 'orderInfo', title: 'Order rules', active: true }
            ],
            pageTitle: 'orderInfo',
            rule: 'admin'
          }
        }
      ]
    },
    // FULL PAGE LAYOUTS
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            rule: 'stock_operator'
          }
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'stock_operator'
          }
        },
        {
          path: '/not-authorized',
          name: 'not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'stock_operator'
          }
        }
      ]
    },
    // SUPPLIERS PANEL
    // {
    //   path: '',
    //   component: () => import('@/layouts/supplier/SupplierPanel.vue'),
    //   children: [
    //     {
    //       path: '/supplier-dashboard',
    //       name: 'supplierDashboard',
    //       component: () => import('@/views/supplier/home.vue'),
    //       meta: {
    //         rule: 'supplier'
    //       }
    //     },
    //     {
    //       path: '/supplier-dashboard/orders/:id',
    //       name: 'supplierOrder',
    //       component: () => import('@/views/supplier/order.vue'),
    //       meta: {
    //         rule: 'supplier'
    //       }
    //     }
    //   ]
    // },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  if (!store.getters['auth/isUserLoggedIn'] && localStorage.getItem('accessToken')) {
    await store.dispatch('auth/autoLogin', localStorage.getItem('accessToken'))
      .catch(() => {
        localStorage.removeItem('accessToken')
      })
  }
  if (to.path === '/not-authorized') {
    return next()
  }  
  // if (to.path.startsWith('/supplier-dashboard')) {
  //   return next()
  // }  
  if (to.path === '/login') {
    if (store.getters['auth/isUserLoggedIn']) {
      router.push({ path: '/' })
    }
    return next()
  }
  if (!store.getters['auth/isUserLoggedIn']) {
    router.push({ path: '/login', query: { to: to.path } })
  }
  return next()
})
export default router

import Vue from 'vue'
import router from '@/router'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
Vue.use(AclInstaller)

let initialRole = 'stock_operator'
const userRole = localStorage.getItem('userRole')
if (userRole) initialRole = userRole
// if (localStorage.getItem('supplierAccessToken')) { initialRole = 'supplier' }

export default new AclCreate({
  initial  : initialRole,
  notfound : '/not-authorized',
  router,
  acceptLocalRules : true,
  globalRules: {
    superadmin  : new AclRule('superadmin').generate(),
    admin  : new AclRule('admin').or('superadmin').generate(),
    operator : new AclRule('operator').or('admin').or('superadmin').generate(),
    stock_operator : new AclRule('stock_operator').or('operator').or('admin').or('superadmin').generate()
    // supplier : new AclRule('supplier').generate()
  }
})

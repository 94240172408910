import jwt from '../../http/requests/auth/jwt/index.js'
// import axios from 'axios'

export default {
  logout ({ commit }) {
    commit('UPDATE_USER_INFO', {}, {root: true})
    commit('LOGOUT_USER')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('accessToken')
  },
  fetchUser (context, token) {
    return new Promise((resolve, reject) => {
      jwt.me(token || localStorage.getItem('accessToken'))
        .then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
    })
  },
  loginJWT ({ commit, dispatch }, payload) {
    // console.log('logging in...')
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.username, payload.userDetails.password)
        .then(response => {
          if (response.data.token) {
            localStorage.setItem('accessToken', response.data.token)
            dispatch('fetchUser', response.data.token)
              .then((res) => {
                commit('UPDATE_USER_INFO', { ...res.data.you }, {root: true})
                commit('LOGIN_USER', { ...res.data.you })
                commit('SET_BEARER', response.data.token)
                resolve(response)
              })
              .catch(err => {
                reject(err)
              })
          } else {
            reject({message: 'Wrong Email or Password'})
          }
        })
        .catch(error => {
          // console.log(JSON.stringify(error.response.data.message))
          reject(error.response.data)
        })
    })
  },
  // loginSupplierJWT (_, payload) {
  //   const baseURL = 'http://192.168.0.145:5125/api/v1'
  //   return new Promise((resolve, reject) => {
  //     axios.post(`${baseURL}/suppliers/login`, { username: payload.username, password: payload.password })
  //       .then(response => {
  //         if (response.data.token) {
  //           localStorage.setItem('supplierAccessToken', response.data.token)
  //           resolve(response)
  //         } else {
  //           reject({message: 'Wrong Email or Password'})
  //         }
  //       })
  //       .catch(error => {
  //         reject(error.response.data)
  //       })
  //   })
  // },
  autoLogin ({ commit, dispatch }, payload) {
    // console.log('auto logginign')
    // console.log(payload)
    return new Promise((resolve, reject) => {
      dispatch('fetchUser', payload)
        .then((res) => {
          commit('UPDATE_USER_INFO', { ...res.data.you }, {root: true})
          commit('LOGIN_USER', { ...res.data.you })
          commit('SET_BEARER', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setToken ({commit}, token) {
    commit('SET_BEARER', token)
  }
}

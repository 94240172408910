const tm = {
  dashboard: 'Panel',
  orders: 'Sargytlar',
  order: 'Sargyt',
  bulkOrders: 'Optom Sargytlar',
  bulkOrder: 'Optom Sargyt',
  store: 'Dükan',
  banners: 'Bannerlar',
  banner: 'Banner',
  categories: 'Kategoriýalar',
  category: 'Kategoriýa',
  brands: 'Brendlar',
  brand: 'Brend',
  products: 'Harytlar',
  product: 'Haryt',
  comboProducts: 'Kombo Harytlar',
  comboProduct: 'Kombo Haryt',
  campaigns: 'Aksiýalar',
  campaign: 'Aksiýa',
  promoCodes: 'Promo kodlar',
  promoCode: 'Promo kod',
  outOfStock: 'Elýeter däl',
  supplierOrders: 'Postawşik Sargytlar',
  supplierOrder: 'Postawşik Sargyt',
  entertainment: 'Güýmenje',
  staff: 'Işgärler',
  users: 'Ulanyjylar',
  user: 'Ulanyjy',
  operators: 'Operatorlar',
  operator: 'Operator',
  couriers: 'Kurýerler',
  courier: 'Kurýer',
  suppliers: 'Postawşikler',
  supplier: 'Postawşik',
  wholesalers: 'Optowiklar',
  wholesaler: 'Optowik',
  settings: 'Sazlamalar',
  regions: 'Regionlar',
  region: 'Region',
  delivery: 'Eltip bermek',
  faqs: 'Sorag-Jogap',
  contacts: 'Kontaktlar',
  contactUs: 'Habarlaşmak',
  aboutUs: 'Biz barada',
  termsConditions: 'Ulanyş kadalary',
  deliveryPolicies: 'Eltip bermek şertleri',
  paymentInfo: 'Töleg şertleri',
  orderInfo: 'Sargyt etmek',
  smsSenders: 'SMS iberijiler',
  details: 'Ginişleyin',
  edit: 'Üýtgetmek',
  delete: 'Pozmak',
  create: 'Täze goş',
  add_new: 'Täze goş',
  tableColumns: 'Sütünler',
  status: 'Status',
  role: 'Rol',
  all: 'Ählisi',
  active: 'Aktiw',
  blocked: 'Bloklanan',
  block: 'Blok etmek',
  unblock: 'Blokdan aýyrmak',
  confirmBlock: 'Hakykatdan hem blok etmek isleýärsiňizmi',
  confirmUnblock: 'Hakykatdan hem blokdan açmak isleýärsiňizmi',
  confirmDelete: 'Hakykatdan hem pozmak isleýärsiňizmi',
  isBlocked: 'Bloklanan',
  only_active: 'Diňe aktiw',
  sale: 'Arzanladyş',
  soldOut: 'Satylyp gutaranlar',
  passive: 'Passiw',
  only_passive: 'Diňe passiw',
  property: 'Aýratynlyklary',
  value: 'Maglumat',
  both: 'Ikisem',
  market: 'Market',
  express: 'Ekspres',
  select: 'Saýlaň',
  isNotActive: 'aktiw däl',
  popularity: 'meşhurlygy',
  makeOrder: 'Sargyt ugratmak',
  ordered: 'Sargalan',
  quantity: 'Mukdary',
  qt: 'San.',
  pcs: 'sany',
  city: 'Şäher',
  cities: 'Şäherler',
  isExpress: 'Ekspres raýonmy?',
  deliveryDuration: 'Eltmek wagty(мin.)',
  deliveryTime: 'Eltip berilmeli wagt',
  deliveryTypes: 'Eltip bermegiň görnüşleri',
  deliveryType: 'Eltip bermegiň görnüşi',
  deliveryTimeRanges: 'Eltip bermegiň sagat aralyklary',
  note: 'Bellik',
  changePassword: 'Paroly üýtgetmek',
  password: 'parol',
  newPassword: 'Täze parol',
  confirmPassword: 'Paroly tassyklaň',
  cashWithdraw: 'Nagt puly çekmek',
  confirmCashWithdraw: 'Hakykatdan hem nagt puly çekmek isleýärsiňizmi',
  fields: {
    id: 'ID',
    name_tm: 'Ady TM.',
    name_ru: 'Ady RU.',
    name_en: 'Ady EN.',
    code: 'Kody',
    shelf_code: 'Polka kody',
    cost: 'Bahasy',
    name: 'Ady',
    username: 'Ulanyjy ady',
    email: 'E-poçta',
    phone: 'Telefon',
    bar_code: 'Barkody',
    priority: 'Ýerleşdirilişi',
    title: 'Ady',
    description: 'Düşündiriş',
    attached: 'Baglanan',
    deadlines: 'Wagty',
    image: 'surat',
    child: 'Goşmaça',
    link: 'Baglanyşyk',
    status: 'Ýagdaýy',
    type: 'Görnüşi',
    discount_type: 'Arzanladyş görnüşi',
    discount_amount: 'Arzanladyş mukdary',
    actions: 'Amallar',
    items: 'Harytlar',
    parent: 'Esas',
    isActive: 'Aktiw my?',
    active: 'Aktiw',
    new: 'Täze',
    isNewProduct: 'Täze haryt my?',
    hit: 'Hit',
    isHitProduct: 'Hit haryt my?',
    published: 'Çap edildimi',
    attachTo: 'Bagla',
    state: 'Ýagdaýy',
    properties: 'Häsiýetleri',
    price: 'Bahasy',
    bulkPrice: 'Lomay bahasy',
    minBulkOrder: 'Min. lomay sargyt mukdary',
    stock_quantity: 'Ammardaky sany',
    stock: 'Ammar',
    stockQt: 'Ammardaky sany',
    minStockQt: 'Ammardaky min. sany',
    maxExpressOrderQt: 'Ekspres sargyt maks. sany',
    weight: 'Agramy',
    volume: 'Göwrümi',
    isBulk: 'Lomaý haryt my',
    fromTo: 'Başlangyç - Soňy',
    maxUse: 'Maks. ulanyş sany',
    promoRule: 'Ulanyş kadasy',
    timeRestricted: 'Wagt bilen çäklendirilen',
    maxUseRestricted: 'Ulanyş sany bilen çäklendirilen',
    owner: 'Eýesi',
    address: 'adres',
    total: 'Umumy',
    subtotal: 'Jemi',
    isAdd: 'Reklama',
    itemName: 'Haryt ady',
    amount: 'Mukdary',
    cash: 'Nagt pul',
    tasks: 'Işler',
    canPublished: 'Çap edilyami?',
    given_price: 'Berilen baha',
    likes: 'Halanlar',
    brandId: 'Brend',
    categories: 'Kategoriýasy',
    total_price: 'Bahasy',
    user_name: 'Ulanyjy ady',
    user_phone: 'Tel. belgisi',
    delivery_type: 'Eltip b. görnüşi',
    delivery_time: 'Eltip b. wagty',
    comment_by_user: 'Kommentariýa',
    isSynced: 'Sinhron.',
    createdAt: 'Döredilen',
    updatedAt: 'Üýtgedilen'
  },
  and: 'we',
  isLeafCat: 'Ýaprak kategoriýa my',
  doesHasParentCat: 'Esasy kategoriýasy barmy',
  attachTo: 'Baglaň',
  type: 'Görnüşi',
  state: 'Ýagdaýy',
  reason: 'Sebäbi',
  enterReason: 'Sebäbini ýazyň',
  selectOne: 'Birini saýlaň',
  selectProducts: 'Harytlary saýlaň',
  selectCombos: 'Kombo haryt saýlaň',
  isActive: 'Aktiw my',
  canBePublished: 'Çap etmäne taýýar my',
  submit: 'Tassykla',
  sent: 'Ugrat',
  cancel: 'Ýatyr',
  reject: 'Ret et',
  upload: 'Ýüklemek',
  update: 'Täzele',
  reorder: 'Tertipleşdirmek',
  change: 'Çalyşmak',
  remove: 'Pozmak',
  sales: 'Arzanladyşlar',
  hits: 'Hit harytlar',
  sort: 'Saýhallamak',
  leafs: 'Ýaprak kat.',
  only_leaf: 'Diňe ýaprak kat.',
  except_leaf: 'Ýaprak kat. başga',
  parents: 'Esasy',
  only_parent: 'Diňe esasylar',
  image: 'surat',
  add_image: 'Surat goşmak',
  deleteFile: 'Faýly pozmal',
  uploadFile: 'Faýl ýüklemek',
  video: 'wideo',
  discount: {
    d: 'arzanladyş',
    amount: 'Arzanladyş mukdary',
    add: 'Arzanladyş goşmak',
    apply: 'Arzanladyşy ulan',
    delete: 'Arzanladyşy pozmak',
    type: 'Arzanladyş görnüşi',
    selectDep: 'Bölüm saýlaň',
    selectStartDate: 'Başlangyç sene saýlaň',
    selectEndDate: 'Gutaryş sene saýlaň',
    selectStartTime: 'Başlangyç wagt saýlaň',
    selectEndTime: 'Gutaryş wagt saýlaň',
    required: 'Alynmalysy',
    bonus: 'Bonus'
  },
  props: {
    id: 'ID',
    uuid: 'UUID',
    code: 'Kod',
    bar_code: 'Barkod',
    shelf_code: 'Polka kody',
    image: 'Surat',
    photo: 'Surat',
    name: 'Ady',
    username: 'Ulanyjy ady',
    email: 'E-poçta',
    phone: 'Telefon',
    video: 'Wideo',
    images: 'Suratlar',
    name_tm: 'Ady TM',
    name_ru: 'Ady RU',
    name_en: 'Ady EN',
    title_tm: 'Ady TM',
    title_ru: 'Ady RU',
    title_en: 'Ady EN',
    description_tm: 'Düşündiriş TM',
    description_ru: 'Düşündiriş RU',
    description_en: 'Düşündiriş EN',
    priority: 'Ýerleşdirilişi',
    role: 'Rol',
    isLeaf: 'Ýaprak kat.my?',
    parentId: 'Esasy kat. ID',
    canPublished: 'Çap etmäne taýýar my?',
    url: 'Baglanyşyk',
    child_type: 'Baglanyşygyň görnüşi.',
    childId: 'Baglanyşygyň IDsy',
    child_categoryIds: 'Baglanyşygyň kategoriýalary',
    child_brandId: 'Baglanyşygyň brendy',
    image_isAdded: 'Surat goşulan my?',
    type: 'Görnüşi',
    isActive: 'Aktiw my?',
    category_brands: 'Kategoriýanyň brandleri',
    brand_categories: 'Brendiň kategoriýalary',
    brandId: 'Brend ID',
    products: 'harytlar',
    collections: 'kombo harytlar',
    categoryId: 'kategoriýa ID',
    createdAt: 'Döredilen',
    updatedAt: 'Üýtgedilen',
    brand: 'brend',
    category: 'kategoriýa',
    categories: 'kategoriýalar',
    discount: 'Arzanladyş',
    price: 'Baha',
    price_express: 'Ekspres baha',
    given_price: 'Berilen baha',
    isBulk: 'Lomay baha',
    isNew: 'Täze mi',
    isHit: 'Hit my',
    weight: 'Agramy',
    volume: 'Göwrümi',
    express_max: 'Ekspres sargyt maks. sany',
    stock_quantity: 'Ammardaky sany',
    stock_min: 'Ammardaky min. sany',
    isEnoughInStock: 'Ammarda ýeterlik mi',
    supplierId: 'Üpjün edijiniň IDsy',
    ownerId: 'Eýesiniň IDsy',
    likes: 'Halanlar',
    collection_products: 'Kolleksiýanyň harytlary',
    totalPrice: 'Umumy baha',
    payment: 'Töleg',
    deliveryType: 'Eltip bermek görnüşi',
    deliveryCost: 'Eltip bermek bahasy',
    deliveryTime: 'Eltip bermek wagty',
    isSynced: 'Sinhronlaşdyrylanmy',
    isAdd: 'Reklama',
    preview: 'Deslapky syn',
    collection: 'Kombo haryt',
    promos: 'Promo kod',
    orders_delivered: 'Eltip berilen sargytlar',
    orders_rejected: 'Ret edilen sargytlar',
    isBlocked: 'Blok edilen mi?',
    isVerified: 'Tassyklanan my?',
    isSupplier: 'Üpjün ediji mi?',
    company_name: 'Kärhananyň ady',
    addresses: 'Adresler',
    isExpress: 'Ekspres my',
    cash: 'Nagt pul',
    tasks_all: 'Ähli eltilen sargytlar',
    tasks_today: 'Şugünki eltilen sargytlar',
    carrier_regions: 'Raýonlar',
    isCredited: 'Kredit mümkinmi',
    price_bulk: 'Optom baha',
    bulk_min: 'Min.lomaý sargyt sany',
    from: 'Başlangyç',
    to: 'Soňy',
    ordered: 'Sargalan',
    unordered: 'Galan',
    password: 'parol',
    in_carrier_stock: 'Kurýerlerde',
    supplier_products: 'Harytlary'
  },
  notify: {
    success: 'Üstünlikli',
    sCreated: 'üstünlikli döredildi!',
    sAdded: 'üstünlikli goşuldy!',
    sUploaded: 'üstünlikli yüklendi!',
    sUpdated: 'üstünlikli täzelendi!',
    sAccepted: 'üstünlikli kabul edildi!',
    sSent: 'üstünlikli ugradyldy!',
    sDelivered: 'üstünlikli eltip berildi!',
    sRejected: 'üstünlikli inkär edildi!',
    sNotDelivered: 'eltip berilmedi!',
    sDeleted: 'üstünlikli pozuldy!',
    sRemoved: 'üstünlikli aýyryldy!',
    error: 'Ýalňyşlyk',
    errOccured: 'Ýalňyşlyk döredi. Bir salymdan barlap görüň',
    formInvalid: 'Forma saz däl. Täzeden barlap görüň.'
  },
  search: 'gözleg',
  questionTurkmen: 'Sorag TM',
  questionEnglish: 'Sorag RU',
  questionRussian: 'Sorag EN',
  answerTurkmen: 'Jogap TM',
  answerEnglish: 'Jogap RU',
  answerRussian: 'Jogap EN',
  tm: 'Türkmençe',
  en: 'Inglizçe',
  ru: 'Rusça',
  fillAllFields: 'Haýyş, formyň ähli bölümlerini dolduryň!',
  addresses: 'Adresler',
  phoneNumbers: 'Telefon belgiler',
  emailAddresses: 'E-poçta adresler',
  socialAccounts: 'Sosiýal ulgamlar',
  workTime: 'Iş sagatlary',
  orderDetails: 'Sargyt maglumatlary',
  orderItems: 'Sargyt harytlary',
  userDetails: 'Satyn alyjy maglumatlary',
  courierDetails: 'Kurýer maglumatlary',
  print: 'Çap et',
  invoice: 'Hasap-faktura',
  date: 'Sene',
  recipient: 'Alyjy',
  deliveries: 'Sargytlar',
  localStock: 'Ýanyndakylar',
  orderPage: {
    all: 'Ählisi',
    pending: 'Garaşylýar',
    accepted: 'Kabul edildi',
    accept: 'Kabul et',
    rejected: 'Ret edilen',
    reject: 'Ret etmek',
    delivered: 'Eltilen',
    sendTo: 'Ugrat',
    send: 'Ugradylan',
    sent: 'Ugradylan',
    deliver: 'Eltip berildi',
    notDelivered: 'Eltilmedik',
    not_delivered: 'Eltilmedik',
    notDeliver: 'Eltip berilmedi',
    filter: 'Filtr',
    dateFrom: 'Başlangyç sene',
    dateTo: 'Gutaryş sene',
    selectDate: 'Sene saýlaň',
    searchBy: 'Gözle',
    rejectiongOrder: 'Ret edýärsiňiz',
    confirmAccept: 'Kabul edişi tassyklaň',
    acceptingOrder: 'Kabul edýärsiňiz',
    sendingOrder: 'Sargydy ugradýarsyňyz',
    confirmDeliver: 'Eltip berilendigini tassyklaň',
    deliveringOrder: 'Eltip berýärsiňiz',
    notDeliveringOrder: 'Eltip bermeýärsiňiz',
    rating: 'Baha',
    userComment: 'Ulanyjyň teswiri',
    undefined: 'Näbelli'
  },
  profile: 'Hasabym',
  logOut: 'Çykmak',
  new: 'Täze',
  notifications: 'Duýduryşlar',
  file_size_max: 'Faýlyň ölçegi rugsat edileninden uly!',
  imageNotAdded: 'Surat goşulmadyk, ilki surat goşuň!!'
}

export default tm